<template>
    <div>
        <b-alert show>登入人次：{{ this.count }}</b-alert>
    </div>
</template>

<script>
export default {
    data() {
        return{
            count: "",
        }
    },
    created: function() {
        this.axios.get(this.GLOBAL.serverSrc + "view_count.php?show=1").then((response) => {
            this.count = response.data
        })
    },
}
</script>